.datePickerForm .react-datepicker {
  border: none !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
}

.datePickerForm .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-top: 0px !important;
}
.datePickerForm .react-datepicker__header {
  text-align: center;
  background-color: white !important;
  border-bottom: 1px solid white !important;
  border-top-left-radius: 0.3rem;
  padding: 15px 0px !important;
  position: relative;
}

.datePickerForm .react-datepicker__navigation {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  height: 25px !important;
  width: 25px !important;
  border-radius: 8px !important;
  top: 12px !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.datePickerForm .react-datepicker__navigation-icon--next {
  left: 0px !important;
}
.datePickerForm .react-datepicker__navigation-icon--previous {
  left: 0px !important;
}

.datePickerForm .react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -6px !important;
}
.datePickerForm .react-datepicker__day-names {
  margin-top: 12px !important;
  margin-bottom: -17px !important;
  color: #aeaeae !important;
  font-weight: 100 !important;
}

.datePickerForm .react-datepicker__day-name{
  color: #aeaeae !important;
  font-weight: 500 !important;
}

.datePickerForm .react-datepicker__day-name:first-letter {text-transform: uppercase !important}

.datePickerForm .react-datepicker__day {
  font-weight: 600 !important;
}
.datePickerForm .react-datepicker__year-read-view--down-arrow,
.datePickerForm .react-datepicker__month-read-view--down-arrow,
.datePickerForm .react-datepicker__month-year-read-view--down-arrow,
.datePickerForm .react-datepicker__navigation-icon::before {
  top: 12px !important;
  border-width: 2px 2px 0 0 !important;
  height: 8px !important;
  width: 8px !important;
}

.datePickerForm .react-datepicker__day--keyboard-selected {
  border-radius: 35px !important;
}
.datePickerForm .react-datepicker__day:hover {
  border-radius: 35px !important;
}

.datePickerForm .react-datepicker__day--in-range {
  border-radius: 35px !important;
}
.datePickerForm .react-datepicker__day--in-range:hover {
  border-radius: 35px !important;
}

.datePickerForm .react-datepicker__day--in-selecting-range {
  border-radius: 35px !important;
}

.datePickerForm .react-datepicker__input-container input {
  background-color: #ffff !important;
  border: 1px solid #e3e3e3;
  border-radius: 5px !important;
  height: 45px !important;
  width: 100% !important;
  color: #373737 !important;
  font-size: 1.05em !important;
  font-weight: bold !important;
  display: flex;
  align-items: center;
  /* padding: 20px; */
}
.datePickerForm .react-datepicker__close-icon {
  padding: 4px 40px 0 0 !important;
}
.datePickerForm .react-datepicker__close-icon::after{
  cursor: pointer;
  background-color:transparent !important;
  color: black !important;
  height: 16px;
  width: 16px !important;
  padding: 2px;
  font-size: 22px !important;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}
.datePickerForm .react-datepicker-wrapper{
  width: 100% !important;
  display: block !important;
  margin-left: 0px !important;
  /* margin-right: 20px !important; */
}
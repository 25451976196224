/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 24, 2020 */

@font-face {
  font-family: 'Avenir-Bold';
  src: url('~assets/fonts/avenirNext/metropolis-bold-webfont.woff2')
      format('woff2'),
    url('~assets/fonts/avenirNext/metropolis-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Medium';
  src: url('~assets/fonts/avenirNext/metropolis-medium-webfont.woff2')
      format('woff2'),
    url('~assets/fonts/avenirNext/metropolis-medium-webfont.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Regular';
  src: url('~assets/fonts/avenirNext/metropolis-regular-webfont.woff2')
      format('woff2'),
    url('~assets/fonts/avenirNext/metropolis-regular-webfont.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Demi';
  src: url('~assets/fonts/avenirNext/metropolis-semibold-webfont.woff2')
      format('woff2'),
    url('~assets/fonts/avenirNext/metropolis-semibold-webfont.woff')
      format('woff');
  font-weight: 700;
  font-style: normal;
}

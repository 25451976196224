body {
  background-color: #ffffff !important;
}
.text-medium {
  font-family: "Avenir-Medium" !important;
}
.text-regular {
  font-family: "Avenir" !important;
}
.text-demi {
  font-family: "Avenir-Demi" !important;
}
.text-bold {
  font-family: "Avenir-Bold" !important;
}
.text-montserrat {
  font-family: "Monserrat" !important;
}
.error {
  color: #ff2a58;
  text-align: center;
  font-size: 10px;
}
.header {
  width: 100%;
  height: 31px;
  background-color: #23b573;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.header .titre {
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
}
.BarSearch .logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.BarSearch .logo img {
  width: 160, 58px;
  height: 70px;
}

.nameCompte {
  float: left;
}

.input-addon {
  position: absolute;
  width: 17px;
  height: auto;
  object-fit: contain;
  right: 15px;
  bottom: 15px;
}

.containerInputSearch .input-addon {
  position: absolute;
  width: 22px;
  height: auto;
  object-fit: contain;
  right: 15px;
  bottom: 12px;
}

.containerInputSearch {
  display: flex;
  align-items: center;
  width: 375px !important;
  height: 45px !important;
}
.inputSearch {
  width: 375px !important;
  height: 45px !important;
  font-size: 18px !important;
  border: 1px solid #23b573 !important;
  color: #23b573 !important;
  border-radius: 8px !important;
}
.containerCompte {
  cursor: pointer !important;
  /* width: 75px; */
}
.containerPanier {
  cursor: pointer !important;
  width: 75px;
}
.containerCompte .image img {
  width: 24px !important;
  height: 23px !important;
  text-align: center !important;
}

.containerCompte .text {
  text-align: center !important;
  font-size: 12px;
  color: #373737;
  letter-spacing: 0;
}
.containerCompte .image {
  /* text-align: right !important; */
}
.containerPanier .image img {
  width: 24px !important;
  height: 23px !important;
  text-align: center !important;
}

.containerPanier .image {
  text-align: center !important;
}
.containerPanier .text {
  text-align: center !important;
  font-size: 12px;
  color: #373737;
  letter-spacing: 0;
}
.Menu {
  width: 100% !important;
  /* height: 27px !important; */
  display: flex;
  align-items: center;
}

.MenuContante hr {
  margin: 0 !important;
}

.Menu .card {
  width: 300px;
  min-height: 300px;
  background: #ffffff;
  position: absolute;
  z-index: 2;
}

.Menu .headerMenu {
  padding: 5px;
  background-color: #23b573;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px !important;
  cursor: pointer;
}

.Menu h3 {
  color: #ffffff;
  margin-bottom: 0px;
  line-height: 2.4em;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
}
.Menu li {
  transition: 1s all;
  font-family: "Roboto", sans-serif;
  border-bottom: 1px solid #e3e3e3;
  font-size: 18px;
  padding: 15px;
  margin-left: -40px;
  margin-top: 0px;
  color: #373737;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.Menu li:hover {
  transition: 1s all;
  color: #23b573;
  font-weight: 700;
  /* background-color: rgba(42, 56, 65, 0.82); */
  cursor: pointer;
}

.BackgroundMenu {
  width: 100%;
  min-height: 175vh;
  height: "auto";
  background-color: #33364b8c !important;
  position: absolute;
  cursor: pointer;
  z-index: 1;
}

.containerFeature {
  display: flex;
  align-items: center;
}

.Feature {
  font-size: 18px;
  color: #373737;
  letter-spacing: 0;
  margin: 0 30px;
  cursor: pointer;
}

.app-main {
  padding: 0px 150px;
  width: 100%;
  height: 100%;
}
.Product {
  background: rgba(53, 226, 148, 0.07);
  border: 1px solid #ebebeb;
  border-radius: 10px;
  width: 206px;
  height: 250px;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
}

.nameProduct {
  font-size: 14px;
  color: #373737;
  letter-spacing: 0;
  margin-left: 5px;
  margin-top: 5px;
}

.discProduct {
  font-size: 10px;
  color: #a7a7a7;
  letter-spacing: 0;
  margin-left: 5px;
}

.footerProduct {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  padding: 0 10px;
  position: absolute;
  bottom: 10px;
  width: 100%;
}
.priceProductHome .ttc {
  font-size: 14px;
  color: #23b573;
  letter-spacing: 0;
}
.priceProductHome .ht {
  font-size: 10px;
  color: #373737;
  letter-spacing: 0;
  margin-left: 2px;
}

.promoProduct {
  background: #ff2a58;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 10px 0 10px 0;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  letter-spacing: 0;
}
.Product .containerImage {
  width: 100%;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.Product .image {
  width: 120px;
  height: 120px;
  position: relative;
  background-color: #fff;
  margin: 0px auto;
}
.Product .addBasket {
  right: 10px;
  cursor: pointer;
}
.Product .breakingMessage {
  color: red;
  font-size: 12px;
}

.footerAfficherPlus {
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.afficherPlus {
  color: #23b573;
  text-align: center;
  padding: 10px;
  border: 1px solid #23b573;
  border-radius: 9px;
  width: 140px;
}
.afficherPlus:hover {
  color: #ffffff;
  text-align: center;
  padding: 10px;
  background-color: #23b573;
  border: 1px solid #23b573;
  border-radius: 9px;
  width: 140px;
}
.headerRayons {
  background-color: #eefcf6;
  color: #23b573;
  width: 100%;
  height: 50px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.breadcrumbRayons {
  font-size: 16px;
  color: #373737;
  letter-spacing: 0;
}
.detailProduct {
  margin-top: 20px;
}
.detailProduct .image {
  width: 100%;
  height: 250px;
  text-align: center;
}
.detailProduct .salePackaging {
  font-size: 16px;
  color: #656565;
}

.detailProduct .detail {
  height: 250px;
  position: relative;
}
.detailProduct .detail .footerDetail {
  position: absolute;
  bottom: 10px;
}

.detailProduct .detail .title {
  font-size: 34px;
  color: #373737;
  letter-spacing: 0;
  line-height: 39px;
  padding-top: 0px;
}

.detailProduct .detail .description {
  font-size: 20px;
  color: #373737;
  letter-spacing: 0;
}
.detailProduct .detail .priceTtc {
  font-size: 30px;
  color: #23b573;
  letter-spacing: 0;
  line-height: 39px;
}
.detailProduct .detail .priceHt {
  font-size: 16px;
  color: #373737;
  letter-spacing: 0;
}
.btnBasket {
  display: flex;
  background-color: #23b573;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 220px;
  cursor: pointer;
}
.btnBasket:hover {
  background-color: #1a9b61;
}

.btnBasket .text {
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
}
.modal-content .modal-header button {
  right: 15px !important;
  top: 10px !important;
}
.inputSearch {
  width: 375px !important;
  height: 45px !important;
  font-size: 18px !important;
  border: 1px solid #e3e3e3 !important;
  color: #373737 !important;
  border-radius: 8px !important;
}
.BtnSignIn {
  width: 375px !important;
  height: 45px !important;
  font-size: 16px !important;
  background-color: #23b573 !important;
  border: 1px solid #23b573 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
}
.createOrder .BtnSignIn {
  width: 275px !important;
  height: 45px !important;
  display: flex;
  justify-content: space-around;
  font-size: 16px !important;
  background-color: #ffb500 !important;
  border: 1px solid #ffb500 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
}
.TextSignUp {
  font-size: 14px;
  color: #1a96c4;
  letter-spacing: 0;
  margin-left: 25px;
  cursor: pointer;
}
.Textforgot {
  font-size: 14px;
  color: #373737;
  letter-spacing: 0;
  margin-left: 25px;
  cursor: pointer;
}
.inputPassword {
  width: 375px;
}

.inputPassword .input-addon {
  position: absolute;
  width: 17px;
  height: auto;
  object-fit: contain;
  right: 15px;
  bottom: 10px;
}
.signin .error {
  width: 375px;
}

.forgot .error {
  width: 375px;
}
.signup {
  padding-right: 80px !important;
  padding-left: 80px !important;
}

.signup .input-addon {
  bottom: 11px;
  cursor: pointer;
}
.signup .inputSearch {
  width: 100% !important;
}

.signup .modal-footer {
  justify-content: space-between !important;
  padding: 0px !important;
}

.signup .BtnSignUp {
  width: 175px !important;
  height: 45px !important;
  font-size: 16px !important;
  background-color: #23b573 !important;
  border: 1px solid #23b573 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
}
.basket .header {
  display: flex;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  background-color: #23b573;
  margin-bottom: 20px;
}
.basket .header .title {
  color: #ffffff !important;
  font-size: 18px;
  padding: 0px 0px 0px 60px;
}

.basket .header .sous-title {
  color: #ffffff !important;
  font-size: 14px;
  cursor: pointer;
}
.basket .header .sous-title-articles {
  color: #ffffff !important;
  font-size: 14px;
  padding: 0px 0px 0px 60px;
}

.basket .header .imageClose {
  padding: 0px 10px;
  position: absolute;
  right: 0px;
  top: 10px;
  cursor: pointer;
}

.basket .body {
  display: flex;
}
.basket .body .imageProduct {
  text-align: center;
  padding: 0px 10px;
  justify-content: flex-end;
  display: flex;
}
.basket .body .nameProduct {
  font-size: 12px;
  padding: 0px;
  line-height: 20px;
  margin: 0px;
}
.basket .body .qteProduct {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  align-items: center;
  border: 1px solid #1a9b61;
  border-radius: 6px;
}
.qteProduct {
  display: flex;
  justify-content: space-between;
  padding: 0px 5px;
  align-items: center;
  border: 1px solid #1a9b61;
  border-radius: 6px;
  width: 80px;
}

.detailsProduct .qteProduct {
  display: flex;
  justify-content: space-between;
  padding: 0px 13px;
  align-items: center;
  border: 1px solid #1a9b61;
  border-radius: 6px;
  width: 180px;
  height: 40px;
  margin-top: 15px;
}

.qteProduct img {
  cursor: pointer;
}
.priceProduct {
  font-size: 12px;
  color: #373737;
  letter-spacing: 0;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: 5px;
}
.priceProductHome img {
  cursor: pointer;
}
.MuiDivider-root {
  border: none;
  height: 1px;
  margin: 10px auto !important;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.12);
  width: 95%;
}

.basket .footer {
  position: relative;
  bottom: 10px;
  width: 100%;
  padding: 0px !important;
}

.summary {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 6px 10px;
}

.basket .footer .summary .total {
  font-size: 14px;
  color: #373737;
  letter-spacing: 0;
  margin-right: 10px;
  margin-top: 5px;
}

.basket .footer .summary .price {
  font-size: 14px;
  color: #373737;
  letter-spacing: 0;
  margin-right: 10px;
  margin-top: 5px;
}

.BtnContinuer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.BtnContinuer button {
  width: 255px !important;
  font-size: 14px !important;
}

.ContentBody {
  height: 430px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.createOrder .ContentBody {
  height: 430px;
  overflow: visible;
}
.MuiBadge-badge {
  background-color: #f7e010 !important;
}
.MuiBadge-anchorOriginTopRightRectangle {
  transform: scale(1) translate(10%, -50%) !important;
}

.createOrder .footer {
  position: relative;
  bottom: 10px;
  width: 100%;
  padding: 0px !important;
}

.createOrder .footer .summary .total {
  font-size: 14px;
  color: #373737;
  letter-spacing: 0;
  margin-right: 10px;
  margin-top: 5px;
}

.createOrder .footer .summary .price {
  font-size: 14px;
  color: #373737;
  letter-spacing: 0;
  margin-right: 10px;
  margin-top: 5px;
}

.createOrder .header {
  display: flex;
  justify-content: flex-start;
  height: 60px;
  width: 100%;
  background-color: #23b573;
  margin-bottom: 20px;
}
.createOrder .header .title {
  color: #ffffff !important;
  font-size: 18px;
  padding: 0px 0px 0px 60px;
}

.createOrder .header .sous-title {
  color: #ffffff !important;
  font-size: 14px;
  cursor: pointer;
}
.createOrder .header .sous-title-articles {
  color: #ffffff !important;
  font-size: 14px;
  padding: 0px 0px 0px 60px;
}

.createOrder .header .imageClose {
  padding: 0px 10px;
  position: absolute;
  right: 0px;
  top: 10px;
  cursor: pointer;
}

.createOrder .body {
  display: flex;
}
.createOrder .body .imageProduct {
  text-align: center;
  padding: 0px 10px;
  justify-content: flex-end;
  display: flex;
}
.createOrder .body .nameProduct {
  font-size: 12px;
  padding: 0px;
  line-height: 20px;
  margin: 0px;
}
.createOrder .body .qteProduct {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  align-items: center;
  border: 1px solid #1a9b61;
  border-radius: 6px;
}

.createOrder img {
  cursor: pointer;
}
.createOrder .ContentBody {
  padding: 10px 30px;
}

textarea.form-control {
  background: #f9f9f9 !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 5px !important;
  min-height: 110px !important;
}

.footerConfirm {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.footerConfirm .BtnSignIn {
  width: 175px !important;
}
.ProductDisabled[disabled] {
  pointer-events: none;
  opacity: 0.7;
}
.separateur {
  border: 3px solid #23b573;
}

.createOrder .inputSearch {
  width: 375px !important;
  height: 45px !important;
  font-size: 14px !important;
  border: 1px solid #e3e3e3 !important;
  color: #373737 !important;
  font-weight: 700 !important;
  border-radius: 8px !important;
}

/* .MuiBackdrop-root {
  z-index: 10000 !important;
} */

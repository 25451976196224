.cardBuss {
  width: 149px;
  height: 149px;
  background: #0e4787;
  border-radius: 11.5px;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.cardBuss .titleBuss {
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}

.cardBuss .imageBuss {
  text-align: center;
}

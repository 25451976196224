.MuiStepConnector-alternativeLabel {
  left: calc(-93% + 20px) !important;
  right: calc(93% + 20px) !important;
}
.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 16px;
  text-align: left !important;
}
.MuiStepLabel-root {
  align-items: flex-start !important;
}

.react-datepicker {
  border: none !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-top: 0px !important;
}
.react-datepicker__header {
  text-align: center;
  background-color: white !important;
  border-bottom: 1px solid white !important;
  border-top-left-radius: 0.3rem;
  padding: 15px 0px !important;
  position: relative;
}

.react-datepicker__navigation {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  height: 25px !important;
  width: 25px !important;
  border-radius: 8px !important;
  top: 12px !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.react-datepicker__navigation-icon--next {
  left: 0px !important;
}
.react-datepicker__navigation-icon--previous {
  left: 0px !important;
}

.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -6px !important;
}
.react-datepicker__day-names {
  margin-top: 12px !important;
  margin-bottom: -17px !important;
  color: #aeaeae !important;
  font-weight: 100 !important;
}

.react-datepicker__day-name{
  color: #aeaeae !important;
  font-weight: 500 !important;
}

.react-datepicker__day-name:first-letter {text-transform: uppercase !important}

.react-datepicker__day {
  font-weight: 600 !important;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  top: 12px !important;
  border-width: 2px 2px 0 0 !important;
  height: 8px !important;
  width: 8px !important;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 35px !important;
}
.react-datepicker__day:hover {
  border-radius: 35px !important;
}

.react-datepicker__day--in-range {
  border-radius: 35px !important;
}
.react-datepicker__day--in-range:hover {
  border-radius: 35px !important;
}

.react-datepicker__day--in-selecting-range {
  border-radius: 35px !important;
}

.react-datepicker__input-container input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 200px !important;
  height: 38px;
  /* margin-top: 6px !important; */
  border-radius: 35px;
  border: 1px solid #dee2e6 !important;
  font-size: 12px;
  padding-left: 16px;
}
.react-datepicker__close-icon {
  padding: 4px 8px 0 0 !important;
  left:130px !important;
  top: -3px !important;
}
.react-datepicker__close-icon::after{
  cursor: pointer;
  background-color:transparent !important;
  color: #c5c5c5e3 !important;
  height: 16px;
  width: 16px !important;
  padding: 2px;
  font-size: 22px !important;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}
.react-datepicker-wrapper{
  width: 200px !important;
  display: block !important;
  margin-right: 5px !important;
  /* margin-right: 20px !important; */
}